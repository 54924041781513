import styles from './Login.module.css'
import { Button, Form, notification, Switch } from 'antd'
import { useEffect, useState } from 'react'
import Link from 'next/link'
import { useGoogleLogin } from '@react-oauth/google'
import { useRouter } from 'next/router'
import PasswordInput from '../../components/password-input'
import TextInput from '../../components/text-input'
import { PhoneOutlined, MailOutlined } from '@ant-design/icons'
import { Users } from '../../services/api/users'
import getFirebaseApp from '../../config/initFirebase'
import { GoogleAuthProvider, browserLocalPersistence, browserSessionPersistence, fetchSignInMethodsForEmail, getAuth, setPersistence, signInWithCredential, signInWithEmailAndPassword } from 'firebase/auth'
import withAuth, { AuthAction } from '../../config/withAuth'
import { getLogoUrl } from '../../config'
import HeadComponent from '../../components/head'

const app = getFirebaseApp()
const auth = getAuth(app)

const LoginPage = () => {
	const [isRememberChecked, setIsRememberChecked] = useState(true)
	const [isEmailPasswordLogin, setIsEmailPasswordLogin] = useState(false)
	const [isGoogleLogin, setIsGoogleLogin] = useState(false)
	const [formErrorMessage, setFormErrorMessage] = useState('')
	const [email, setEmail] = useState('')
	const [password, setPassword] = useState('')
	const router = useRouter()
	const { query } = router
	const { redirect, destination } = router.query

	useEffect(() => {
		return () => {
			notification.destroy()
		}
	}, [])

	useEffect(() => {
		if (!email) {
			return
		}
		isValidEmail(email) ? setFormErrorMessage('') : setFormErrorMessage('Invalid Login Credential')
	}, [email])

	useEffect(() => {
		const { subscriptionPayment } = query
		if (subscriptionPayment) {
			switch (subscriptionPayment) {
				case 'success':
					notification.success({
						message: 'Payment successful',
						description: 'Your subscription has been activated. Log in to start using Nuport E-Commerce Suite.',
						placement: 'bottomLeft'
					})
					break
				case 'failed':
					notification.error({
						message: 'Payment failed',
						description: 'Contact your card issuer for assistance. A trial subscription to Nuport E-Commerce Suite has been started for your account in the meantime.',
						placement: 'bottomLeft'
					})
					break
				case 'canceled':
					notification.info({
						message: 'Payment Cancelled',
						description: 'Your subscription payment was cancelled. A trial subscription to Nuport E-Commerce Suite has been started for your account in the meantime.',
						placement: 'bottomLeft'
					})
					break
				default:
					break
			}
		}
	}, [])

	const isValidEmail = (email) => {
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		return re.test(String(email).toLowerCase())
	}

	const onFinish = async () => {
		try {
			if (!email || !password) {
				!email && setEmail(null)
				!password && setPassword(null)
				return
			}
			if (!isValidEmail(email)) {
				return setFormErrorMessage('Invalid Login Credential')
			}

			setIsEmailPasswordLogin(true)
			if (formErrorMessage) {
				setFormErrorMessage('')
			}
			await signInWithEmailAndPassword(auth, email, password)
			if (redirect) {
				window.location.replace(`${redirect}/login?signedIn=true`)
			}
			if (destination) {
				router.replace(destination)
			}
		} catch (e) {
			setIsEmailPasswordLogin(false)
			switch (e.code) {
				case 'auth/network-request-failed':
					setFormErrorMessage('Internet Connection Error')
					break
				case 'auth/user-disabled': {
					try {
						const { data } = await Users.getStatus(email)
						if (data.status === 'new_disabled') {
							setFormErrorMessage('Your account is being verified. You will be notified by our support team within 48 hours of registration.')
							notification.destroy()
							notification.info({
								message: 'Please Wait A While',
								description:
								<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
									<span>Your account is being verified. You will be able to log in within 48 hours of registration.</span>
									<div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
										<PhoneOutlined />
										+880 1325 072 929
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
										<MailOutlined />
										<a href='mailto:support@nuport.io'>support@nuport.io</a>
									</div>
								</div>,
								duration: 10,
								placement: 'bottomLeft'
							})
						} else {
							setFormErrorMessage('Account is disabled. Please contact support to enable your account.')
							notification.destroy()
							notification.info({
								message: 'Unable to Log in',
								description:
								<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
									<span>Your account is disabled. Please contact support to enable your account.</span>
									<div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
										<PhoneOutlined />
										+880 1325 072 929
									</div>
									<div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
										<MailOutlined />
										<a href='mailto:support@nuport.io'>support@nuport.io</a>
									</div>
								</div>,
								duration: 10,
								placement: 'bottomLeft'
							})
						}
					} catch (e) {
						setFormErrorMessage('Account is disabled. Please contact support to enable your account.')
						notification.destroy()
						notification.info({
							message: 'Unable to Log in',
							description:
							<div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
								<span>Your account is disabled. Please contact support to enable your account.</span>
								<div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
									<PhoneOutlined />
									+880 1325 072 929
								</div>
								<div style={{ display: 'flex', alignItems: 'center', gap: 6 }}>
									<MailOutlined />
									<a href='mailto:support@nuport.io'>support@nuport.io</a>
								</div>
							</div>,
							duration: 10,
							placement: 'bottomLeft'
						})
					}
					break
				}
				default:
					setFormErrorMessage('Login Credential Mismatch')
					break
			}
		} finally {
			setIsEmailPasswordLogin(false)
		}
	}

	const onGoogleSignIn = async (response) => {
		try {
			if (!response.error) {
				setIsGoogleLogin(true)
				const tokenResponse = await Users.getGoogleAuthTokens(response.code)
				const tokens = tokenResponse.data
				const userResponse = await fetch(`https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokens.access_token}`)
				const profile = await userResponse.json()
				const email = profile.email
				const methods = await fetchSignInMethodsForEmail(auth, email)
				const idToken = tokens.id_token
				if (methods.length === 0) {
					// User does not exist. Go to sign up flow.
					const name = profile.name
					router.push(`/sign-up?email=${email}${name ? `&name=${name}` : ''}${idToken ? `&idToken=${idToken}` : ''}`)
				} else {
					// User does exist. Sign in like usual.
					const credential = GoogleAuthProvider.credential(idToken)
					await signInWithCredential(auth, credential)
				}
			}
		} catch (e) {
			setIsGoogleLogin(false)
			notification.error({
				message: 'Login Error',
				description: e.message,
				placement: 'bottomLeft'
			})
		} finally {
			setIsGoogleLogin(false)
		}
	}

	const login = useGoogleLogin({
		onSuccess: onGoogleSignIn,
		onFailure: onGoogleSignIn,
		flow: 'auth-code'
	})

	return (
		<>
			<HeadComponent />
			<main className={`${styles.root} login-page`}>
				<Form
					className={styles.loginForm}
					name='basic'
					initialValues={{
						remember: true
					}}
					onFinish={onFinish}
				>
					<div className={styles.logo}>
						<img src={getLogoUrl()} width={216} alt='Nuport logo' style={{ objectFit: 'contain' }} />
					</div>
					{
						formErrorMessage && <div className={styles.errorMessage}>{formErrorMessage}</div>
					}
					<div className={styles.inputsContainer}>
						<TextInput
							title='Email'
							error={email === null}
							value={email}
							onChange={({ target: { value } }) => setEmail(value)}
						/>
						<PasswordInput
							title='Password'
							error={password === null}
							value={password}
							onChange={({ target: { value } }) => setPassword(value)}
						/>
					</div>
					<div className={styles.rememberBtnContainer}>
						<p className={styles.rememberText}>Remember</p>
						<Switch
							checked={isRememberChecked}
							onChange={checked => {
								setIsRememberChecked(checked)
								if (checked) {
									setPersistence(auth, browserLocalPersistence)
								} else {
									setPersistence(auth, browserSessionPersistence)
								}
							}}
						/>
						<Link href='/forgot-password'>
							<a className={styles.forgotPassword}>Forgot Password?</a>
						</Link>
					</div>
					<Button
						className={styles.loginBtn}
						block
						loading={isEmailPasswordLogin}
						type='primary'
						htmlType='submit'
					>
						Log In
					</Button>
					{
						!process.env.NEXT_PUBLIC_CUSTOM_DOMAIN &&
						<>
							<div className={styles.signupLinkContainer}>
								New to Nuport?
								<Link href='/sign-up?step=industry'>
									<a className={styles.signupLink}> Sign Up Now</a>
								</Link>
							</div>
							<div className={styles.orTextContainer}>
								<div className={`${styles.line} ${styles.lineLeft}`} />
								<span>Or</span>
								<div className={`${styles.line} ${styles.lineRight}`} />
							</div>
							<Button
								className={styles.googleSignupBtn}
								block
								loading={isGoogleLogin}
								onClick={() => login()}
							>
								<img src='/img/google.svg' alt='Google logo' />
								<span>
							Continue with Google
								</span>
							</Button>
						</>
					}
				</Form>
			</main>
		</>
	)
}

export default withAuth({
	onAuth: AuthAction.REDIRECT_TO_HOME
})(LoginPage)
